<template>
  <v-list two-line>
    <v-list-item-group no-action>
      <template v-for="(site, index) in sortedSites">
        <v-list-item
          :key="site.id"
          :to="{ name: 'site-details', params: { id: site.id } }"
        >
          <v-list-item-content>
            <v-list-item-title v-text="site.name" />
            <v-list-item-subtitle v-text="site.baseUrl" />
          </v-list-item-content>
          <v-list-item-action-text v-if="site.lastScanned">
            Last Scan: {{ moment(site.lastScanned).format('M/D/YY - h:mm a') }}
          </v-list-item-action-text>
        </v-list-item>

        <v-divider
          v-if="index < sites.length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    props: ['sites'],

    computed: {
      sortedSites: function() {
        return [...this.sites].sort((a, b) => {
          return (b.lastScanned || b.createdAt) - (a.lastScanned || a.createdAt);
        })
      }
    }
  }
</script>
