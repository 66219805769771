<template>
  <v-container>
    <h1 class="mb-3 text-center">Websites</h1>

    <v-card class="pa-4">
      <v-card-title>
        Website List
        <v-btn
          color="primary"
          fab
          x-small
          class="ml-3"
          title="Create new site"
          :to="{ name: 'create-site' }"
        ><v-icon>mdi-plus</v-icon></v-btn>
      </v-card-title>
      <site-list :sites="sites" />
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import SiteList from '@/components/sites/SiteList.vue'

  export default {
    title: process.env.VUE_APP_NAME,

    components: {
      SiteList
    },

    computed: {
      ...mapGetters({ sites: 'sites/siteList' })
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        getSites: 'sites/getSites'
      })
    },

    beforeRouteEnter: async function(to, from, next) {
      await next(async vm => {
        vm.setLoading(true)
        await vm.getSites()
        vm.setLoading(false)
      })
    }
  }
</script>
